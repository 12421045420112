import { clsx } from 'clsx';

import { TAB_LABEL } from './const';

export const LatestInformationSkelton = () => {
  return (
    <div>
      <ul className={clsx('tw-flex')}>
        {Object.entries(TAB_LABEL).map(([key, label]) => (
          <li key={key} className={clsx('tw-flex-1 tw-text-center')}>
            <button
              type="button"
              className={clsx(
                'tw-w-full tw-h-11 tw-text-ui14',
                key === 'contest'
                  ? 'tw-font-bold tw-border-b-2 tw-border-primary-500'
                  : 'hover:tw-opacity-50 tw-transition tw-duration-200'
              )}
              onClick={() => null}
            >
              {label}
            </button>
          </li>
        ))}
      </ul>

      <ul>
        {Array.from(Array(5)).map((_, index) => (
          <li key={index}>
            <article
              className={clsx(
                'tw-flex tw-py-3 tw-border-b tw-border-gray-200 tw-gap-4'
              )}
            >
              <div
                className={clsx(
                  'tw-w-[89px] tw-h-[58px] tw-rounded-md tw-overflow-hidden tw-bg-gray-300 tw-animate-pulse'
                )}
              />
              <div
                className={clsx(
                  'tw-flex-1 tw-flex tw-flex-col tw-justify-between'
                )}
              >
                <p
                  className={clsx(
                    'tw-w-full tw-h-3 tw-mt-1 tw-bg-gray-300 tw-animate-pulse'
                  )}
                />
                <div className={clsx('tw-flex tw-items-center tw-space-x-2')}>
                  <span
                    className={clsx(
                      'tw-w-12 tw-h-3 tw-mt-1 tw-bg-gray-300 tw-animate-pulse'
                    )}
                  />
                </div>
              </div>
            </article>
          </li>
        ))}
      </ul>
    </div>
  );
};
