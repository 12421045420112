import { clsx } from 'clsx';

export const RecommendedLabel = () => {
  return (
    <span
      className={clsx(
        'tw-h-4 tw-px-1 tw-pb-px tw-inline-flex tw-items-center',
        'tw-bg-primary-500 tw-text-ui11 tw-text-white tw-rounded-sm'
      )}
    >
      おすすめ
    </span>
  );
};
