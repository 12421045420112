import { clsx } from 'clsx';

export const SidebarBannersSkelton = () => {
  return (
    <ul
      className={clsx(
        'tw-grid tw-grid-cols-1 tw-gap-2',
        'md:tw-grid-cols-2 lg:tw-grid-cols-1'
      )}
    >
      {Array.from(Array(4)).map((banner, index) => (
        <li
          key={index}
          className={clsx(
            'tw-w-[300px] tw-max-w-full tw-h-[75px] tw-mx-auto',
            'tw-bg-gray-300 tw-animate-pulse'
          )}
        />
      ))}
    </ul>
  );
};
