import { clsx } from 'clsx';

import { AnchorLink } from '@/components/ui';
import { ROUTE } from '@/constants';
import BrandLogo from 'public/images/brand_logo.svg';

export const AppMembershipBanner = () => {
  return (
    <AnchorLink
      className={clsx(
        'w-full tw-flex tw-flex-col tw-items-center tw-py-8 tw-bg-[url("/images/mypage_bg.webp")]',
        'tw-border-t-8 tw-border-primary-500'
      )}
      href={ROUTE.service()}
    >
      <BrandLogo width={164} />

      <p className={clsx('tw-mt-3 tw-text-ui14 tw-font-bold')}>
        会員限定サービスのご案内
      </p>

      <section className={clsx('tw-mt-6')}>
        <div
          className={clsx(
            'tw-inline-flex tw-items-center tw-justify-center',
            'tw-outline-none',
            'tw-bg-primary-500 tw-text-white tw-font-bold',
            'tw-h-[51px] tw-px-8 tw-text-ui16',
            'tw-rounded-full'
          )}
        >
          無料会員登録はこちら
        </div>
      </section>
    </AnchorLink>
  );
};
