import { clsx } from 'clsx';

import type { SidebarBannersProps } from './type';

import { bannerAttributes } from '@/components/features/banner/util';
import { AnchorLink, Image } from '@/components/ui';
import { NO_IMAGE_SRC } from '@/constants';
import { isExternal } from '@/utils/features/banner';

export const SidebarBanners = ({ banners }: SidebarBannersProps) => {
  return (
    <ul className={clsx('tw-flex tw-flex-col tw-gap-y-2')}>
      {banners
        .flatMap((b) => (b.url ? { ...b, url: b.url } : []))
        .map((banner, index) => (
          <li key={index} className={clsx('tw-w-full tw-mx-auto')}>
            <AnchorLink
              {...bannerAttributes({ id: banner.id, type: banner.type })}
              href={banner.url}
              target={isExternal(banner.url) ? '_blank' : '_self'}
            >
              <Image
                src={banner.thumbnail?.file?.url ?? NO_IMAGE_SRC}
                alt={`サイドバーバナー${index}`}
                size={{
                  sp: '640px',
                  tablet: '700px',
                  pc: '300px',
                }}
              />
            </AnchorLink>
          </li>
        ))}
    </ul>
  );
};
