import { clsx } from 'clsx';

export const RectangleBannerSkelton = () => {
  return (
    <>
      {Array.from(Array(1)).map((_, index) => (
        <div
          className={clsx(
            'tw-hidden tw-w-[300px] tw-h-[197px]',
            'lg:tw-block',
            'tw-bg-gray-300 tw-animate-pulse'
          )}
          key={index}
        />
      ))}
    </>
  );
};
