import { clsx } from 'clsx';
import { filter } from 'graphql-anywhere';
import { createElement } from 'react';
import { Section, H } from 'react-headings';

import type { SidebarProps } from '@/components/app/Sidebar/type';

import { AppMembershipBanner } from '@/components/app';
import {
  CompanyBanners,
  CompanyBannersSkelton,
} from '@/components/app/Sidebar/CompanyBanners';
import {
  RectangleBanner,
  RectangleBannerSkelton,
} from '@/components/features/banner';
import { CreatorPostCard } from '@/components/features/creatorPost';
import { GoogleAdsense } from '@/components/features/google';
import { AnchorLink } from '@/components/ui';
import { ADSENSE, ADSENSE_PUBLISHER_CIENT } from '@/constants/google';
import {
  SidebarLatestInformationContestsFragmentDoc,
  SidebarLatestInformationArticlesFragmentDoc,
  SidebarLatestInformationCreatorNotificationsFragmentDoc,
  SidebarClientDocument,
} from '@/graphql/generated';
import { CreatorPostCardCreatorPostsFragmentDoc } from '@/graphql/generated.creator-anonymous';
import { useQueryWrapper } from '@/libs/apollo';

import {
  LatestInformation,
  LatestInformationSkelton,
} from './LatestInformation';
import { SidebarBanners, SidebarBannersSkelton } from './SidebarBanners';
import { ORGANIZER_HELP_LINKS } from './const';

const SidebarForSSR = ({ data, className }: SidebarProps) => {
  return (
    <aside className={clsx('tw-pb-10 lg:tw-w-sidebar', className)}>
      <Section component={null}>
        <section className={clsx('tw-flex tw-justify-center tw-flex-col')}>
          {data ? (
            <>
              {data.bannerRectangleBanners.length > 0 ? (
                <RectangleBanner banners={data.bannerRectangleBanners} />
              ) : (
                ADSENSE_PUBLISHER_CIENT && (
                  <GoogleAdsense
                    client={ADSENSE_PUBLISHER_CIENT}
                    {...ADSENSE.rectangle}
                  />
                )
              )}
            </>
          ) : (
            <RectangleBannerSkelton />
          )}
        </section>

        <section className={clsx('tw-mt-9 tw-px-spContentSide lg:tw-px-0')}>
          <Section
            component={
              <H className={clsx('tw-mb-4 tw-text-head20 tw-font-bold')}>
                新着情報
              </H>
            }
          >
            {data !== undefined ? (
              <LatestInformation
                contests={data.latestContests.map((contest) =>
                  filter(SidebarLatestInformationContestsFragmentDoc, contest)
                )}
                articles={
                  data.latestArticles.map((article) =>
                    filter(SidebarLatestInformationArticlesFragmentDoc, article)
                  ) ?? []
                }
                notifications={
                  data.latestNotifications.map((notification) =>
                    filter(
                      SidebarLatestInformationCreatorNotificationsFragmentDoc,
                      notification
                    )
                  ) ?? []
                }
                banners={data.bannerNewContests}
              />
            ) : (
              <LatestInformationSkelton />
            )}
          </Section>
        </section>

        <section className={clsx('tw-mt-9 tw-px-spContentSide lg:tw-px-0')}>
          <Section
            component={
              <H className={clsx('tw-mb-4 tw-text-head20 tw-font-bold')}>
                つくログ
              </H>
            }
          >
            {data !== undefined ? (
              <ul className={clsx('tw-divide-y tw-divide-gray-200')}>
                {data.creatorPosts.map((post) => (
                  <li key={post.id}>
                    <CreatorPostCard
                      creatorPost={filter(
                        CreatorPostCardCreatorPostsFragmentDoc,
                        post
                      )}
                      isSelf={false}
                      withPadding={false}
                      readOnly
                      onSuccessEdit={() => null}
                      onSuccessDelete={() => null}
                      onSuccessUpdateLike={() => null}
                    />
                  </li>
                ))}
              </ul>
            ) : (
              <ul className={clsx('tw-space-y-2')}>
                {Array.from(Array(5)).map((_, index) => (
                  <li
                    key={index}
                    className={clsx(
                      'tw-w-full tw-h-28 tw-bg-gray-300 tw-animate-pulse'
                    )}
                  />
                ))}
              </ul>
            )}
          </Section>
        </section>

        <section className={clsx('tw-mt-9 tw-px-spContentSide lg:tw-px-0')}>
          <Section
            component={
              <H className={clsx('tw-mb-4 tw-text-head20 tw-font-bold')}>
                公募を主催される皆さまへ
              </H>
            }
          >
            <ul
              className={clsx(
                'tw-flex tw-flex-col tw-gap-y-2',
                'md:tw-flex-row md:tw-gap-x-5 tw-justify-between',
                'lg:tw-flex-col'
              )}
            >
              {ORGANIZER_HELP_LINKS.map(({ label, href, icon }) => (
                <li
                  key={href}
                  className={clsx(
                    'tw-flex-1 tw-border-b tw-border-gray-200',
                    'md:tw-border',
                    'lg:tw-border-t-0 lg:tw-border-x-0'
                  )}
                >
                  <AnchorLink href={href}>
                    <span
                      className={clsx(
                        'tw-w-full tw-h-[60px] md:tw-px-3 tw-flex tw-items-center tw-space-x-2'
                      )}
                    >
                      {createElement(icon, { boxSize: '24px' })}
                      <strong>{label}</strong>
                    </span>
                  </AnchorLink>
                </li>
              ))}
            </ul>
          </Section>
        </section>

        <section className={clsx('tw-mt-9 tw-px-spContentSide lg:tw-px-0')}>
          <AppMembershipBanner />
        </section>

        <section className={clsx('tw-mt-8 tw-px-spContentSide lg:tw-px-0')}>
          {data ? (
            <>
              {data.bannerSidebarBanners.length !== 0 && (
                <SidebarBanners banners={data.bannerSidebarBanners} />
              )}
            </>
          ) : (
            <SidebarBannersSkelton />
          )}
        </section>

        <section className={clsx('tw-mt-8 tw-px-spContentSide lg:tw-px-0')}>
          {data ? (
            <CompanyBanners banners={data.bannerCompanyBanners} />
          ) : (
            <CompanyBannersSkelton />
          )}
        </section>
      </Section>
    </aside>
  );
};

const SidebarForCSR = (props: Omit<SidebarProps, 'data'>) => {
  const { data } = useQueryWrapper(SidebarClientDocument, {
    role: 'anonymous',
  });
  return <SidebarForSSR data={data} {...props} />;
};

export { SidebarForSSR, SidebarForCSR as Sidebar };
