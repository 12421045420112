import { clsx } from 'clsx';
import { useState } from 'react';
import { H } from 'react-headings';

import type { LatestInformationProps, TabKey } from './type';

import { bannerAttributes } from '@/components/features/banner/util';
import { AnchorLink, Image, RecommendedLabel } from '@/components/ui';
import { DATE_FORMAT, NO_IMAGE_SRC, ROUTE } from '@/constants';
import { formatDate } from '@/utils/date';
import { isExternal } from '@/utils/features/banner';

import { TAB_LABEL } from './const';

export const LatestInformation = ({
  contests,
  articles,
  notifications,
  banners,
}: LatestInformationProps) => {
  const [currentTab, setCurrentTab] = useState<TabKey>('contests');

  return (
    <div>
      <ul className={clsx('tw-flex')}>
        {Object.entries(TAB_LABEL).map(([key, label]) => (
          <li key={key} className={clsx('tw-flex-1 tw-text-center')}>
            <button
              type="button"
              className={clsx(
                'tw-w-full tw-h-11 tw-text-ui14',

                currentTab === key
                  ? 'tw-font-bold tw-border-b-2 tw-border-primary-500'
                  : 'hover:tw-opacity-50 tw-transition tw-duration-200'
              )}
              onClick={() => setCurrentTab(key as TabKey)}
            >
              {label}
            </button>
          </li>
        ))}
      </ul>

      {currentTab === 'contests' && (
        <ul>
          {banners
            .flatMap((b) => (b.url ? { ...b, url: b.url } : []))
            .map((banner) => (
              <li key={banner.id}>
                <AnchorLink
                  {...bannerAttributes({ id: banner.id, type: banner.type })}
                  href={banner.url}
                  target={isExternal(banner.url) ? '_blank' : '_self'}
                >
                  <article
                    className={clsx(
                      'tw-flex tw-py-3 tw-border-b tw-border-gray-200 tw-gap-4'
                    )}
                  >
                    <div
                      className={clsx(
                        'tw-w-[89px] tw-h-[58px] tw-rounded-md tw-overflow-hidden'
                      )}
                    >
                      <Image
                        src={banner.thumbnail?.file?.url ?? NO_IMAGE_SRC}
                        alt=""
                        size={{
                          sp: '90px',
                          tablet: '90px',
                          pc: '90px',
                        }}
                      />
                    </div>
                    <div
                      className={clsx(
                        'tw-flex-1 tw-flex tw-flex-col tw-justify-between'
                      )}
                    >
                      <H
                        className={clsx(
                          'tw-text-title12 tw-font-bold tw-line-clamp-2'
                        )}
                      >
                        {banner.lead}
                      </H>
                      <div
                        className={clsx('tw-flex tw-items-center tw-space-x-2')}
                      >
                        <RecommendedLabel />
                      </div>
                    </div>
                  </article>
                </AnchorLink>
              </li>
            ))}

          {contests.map(
            ({ id, name, publishedAt, thumbnail, isRecommended }) => (
              <li key={id}>
                <AnchorLink href={ROUTE.contestDetail(id)}>
                  <article
                    className={clsx(
                      'tw-flex tw-py-3 tw-border-b tw-border-gray-200 tw-gap-4'
                    )}
                  >
                    <div
                      className={clsx(
                        'tw-w-[89px] tw-h-[58px] tw-rounded-md tw-overflow-hidden'
                      )}
                    >
                      <Image
                        src={thumbnail?.file?.url ?? NO_IMAGE_SRC}
                        alt=""
                        size={{
                          sp: '90px',
                          tablet: '90px',
                          pc: '90px',
                        }}
                      />
                    </div>
                    <div
                      className={clsx(
                        'tw-flex-1 tw-flex tw-flex-col tw-justify-between'
                      )}
                    >
                      <H
                        className={clsx(
                          'tw-text-title12 tw-font-bold tw-line-clamp-2'
                        )}
                      >
                        {name}
                      </H>
                      <div
                        className={clsx('tw-flex tw-items-center tw-space-x-2')}
                      >
                        <span className={clsx('tw-text-ui10 tw-text-gray-600')}>
                          {formatDate(publishedAt, DATE_FORMAT.yyyyMMdd)}
                        </span>
                        {isRecommended && <RecommendedLabel />}
                      </div>
                    </div>
                  </article>
                </AnchorLink>
              </li>
            )
          )}
        </ul>
      )}

      {currentTab === 'readings' && (
        <ul>
          {articles
            .flatMap((a) => (a.id ? { ...a, id: a.id } : []))
            .map((article) => (
              <li key={article.id}>
                <AnchorLink href={ROUTE.articleDetail(article.id)}>
                  <article
                    className={clsx(
                      'tw-flex tw-py-3 tw-border-b tw-border-gray-200 tw-gap-4'
                    )}
                  >
                    <div
                      className={clsx(
                        'tw-w-[89px] tw-h-[58px] tw-rounded-md tw-overflow-hidden'
                      )}
                    >
                      <Image
                        src={
                          article.listView?.thumbnail?.file?.url ??
                          article.keyVisual?.file?.url ??
                          NO_IMAGE_SRC
                        }
                        alt=""
                        size={{
                          sp: '90px',
                          tablet: '90px',
                          pc: '90px',
                        }}
                      />
                    </div>
                    <div
                      className={clsx(
                        'tw-flex-1 tw-flex tw-flex-col tw-justify-between'
                      )}
                    >
                      <H
                        className={clsx(
                          'tw-text-title12 tw-font-bold tw-line-clamp-2'
                        )}
                      >
                        {article.listView?.lead ?? article?.title ?? ''}
                      </H>
                      <span className={clsx('tw-text-ui10 tw-text-gray-600')}>
                        {formatDate(article.publishedAt, DATE_FORMAT.yyyyMMdd)}
                      </span>
                    </div>
                  </article>
                </AnchorLink>
              </li>
            ))}
        </ul>
      )}

      {currentTab === 'notifications' && (
        <ul>
          {notifications
            .flatMap((n) => (n.id ? { ...n, id: n.id } : []))
            .map((notification) => (
              <li key={notification.id}>
                <AnchorLink href={ROUTE.notificationDetail(notification.id)}>
                  <article
                    className={clsx(
                      'tw-flex tw-py-3 tw-border-b tw-border-gray-200 tw-gap-4'
                    )}
                  >
                    <div
                      className={clsx(
                        'tw-flex-1 tw-flex tw-flex-col tw-justify-between'
                      )}
                    >
                      <H
                        className={clsx(
                          'tw-text-title12 tw-font-bold tw-line-clamp-2'
                        )}
                      >
                        {notification.title}
                      </H>
                      <span
                        className={clsx(
                          'tw-mt-1 tw-text-ui10 tw-text-gray-600'
                        )}
                      >
                        {formatDate(
                          notification.publishedAt,
                          DATE_FORMAT.yyyyMMdd
                        )}
                      </span>
                    </div>
                  </article>
                </AnchorLink>
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};
