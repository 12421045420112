import { filter } from 'graphql-anywhere';

import type { LayoutWithSidebarProps } from './type';
import type { ReactNode } from 'react';

import { SidebarForSSR } from '@/components/app';
import {
  CommonLayoutCreatorsFragmentDoc,
  LayoutWithSidebarClientMeDocument,
  LayoutWithSidebarClientSidebarDocument,
  LayoutWithSidebarCreatorsFragmentDoc,
  SidebarFragmentDoc,
} from '@/graphql/generated';
import { CommonLayoutForSSR, Container, SidebarLayout } from '@/layouts';
import { useQueryWrapper } from '@/libs/apollo';

const LayoutWithSidebarForSSR = ({
  isLeftSidebar,
  subHeader,
  creator,
  children,
  ...props
}: LayoutWithSidebarProps) => {
  return (
    <CommonLayoutForSSR
      subHeader={subHeader}
      creator={
        creator ? filter(CommonLayoutCreatorsFragmentDoc, creator) : undefined
      }
    >
      <Container>
        <SidebarLayout
          isLeftSidebar={isLeftSidebar}
          sidebar={
            props.isDefaultSidebar ? (
              <SidebarForSSR
                data={
                  props.sidebarData
                    ? filter(SidebarFragmentDoc, props.sidebarData)
                    : undefined
                }
              />
            ) : (
              props.sidebar
            )
          }
        >
          {children}
        </SidebarLayout>
      </Container>
    </CommonLayoutForSSR>
  );
};

const LayoutWithSidebarForCSR = (
  props: Omit<
    LayoutWithSidebarProps,
    'data' | 'creator' | 'isDefaultSidebar'
  > & { sidebar?: ReactNode }
) => {
  const { data: sidebarData } = useQueryWrapper(
    LayoutWithSidebarClientSidebarDocument,
    {
      role: 'anonymous',
      skip: !!props.sidebar,
    }
  );
  const { data: meData } = useQueryWrapper(LayoutWithSidebarClientMeDocument);
  return (
    <LayoutWithSidebarForSSR
      creator={
        meData?.me?.creator
          ? filter(LayoutWithSidebarCreatorsFragmentDoc, meData.me.creator)
          : undefined
      }
      {...props}
      {...(props.sidebar
        ? {
            isDefaultSidebar: false,
            sidebar: props.sidebar,
          }
        : {
            isDefaultSidebar: true,
            sidebarData: sidebarData
              ? filter(SidebarFragmentDoc, sidebarData)
              : undefined,
          })}
    />
  );
};

export {
  LayoutWithSidebarForSSR,
  LayoutWithSidebarForCSR as LayoutWithSidebar,
};
