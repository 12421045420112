import clsx from 'clsx';

export const ADSENSE_PUBLISHER_CIENT =
  process.env.NEXT_PUBLIC_ADSENSE_PUBLISHER_CIENT ?? '';

export type AdsenseOption = {
  format: 'auto' | 'fluid' | 'rectangle';
  layoutKey?: string;
  slot: string;
  responsive?: 'true' | 'false'; // パッケージ都合
  className?: string;
};

type AdsenseType =
  | 'rectangle'
  | 'contestTopForPC'
  | 'contestTopForSP'
  | 'contestListForPC'
  | 'contestListForSP'
  | 'contestDetail'
  | 'top';

export const ADSENSE: Record<AdsenseType, AdsenseOption> = {
  rectangle: {
    format: 'rectangle',
    slot: '6462590086',
    responsive: 'true',
    className: clsx('tw-w-full tw-h-[280px] sm:tw-h-[250px]'),
  },
  contestTopForPC: {
    format: 'fluid',
    slot: '5696303329',
    layoutKey: '-h7-f+2d-5v+6m',
    className: clsx('tw-w-full tw-h-[116px]'),
  },
  contestTopForSP: {
    format: 'fluid',
    slot: '8376053233',
    layoutKey: '-i6-b+2d-4e+2o',
    className: clsx('tw-w-full tw-h-[116px]'),
  },
  contestListForPC: {
    format: 'fluid',
    slot: '5868797368',
    layoutKey: '-h7-f+2d-5v+6m',
    className: clsx('tw-w-full tw-h-[116px]'),
  },
  contestListForSP: {
    format: 'fluid',
    slot: '2400040232',
    layoutKey: '-fb+5w+4e-db+86',
    className: clsx('tw-w-full tw-h-[116px]'),
  },
  contestDetail: {
    format: 'rectangle',
    slot: '5090856838',
    responsive: 'true',
  },
  top: {
    format: 'rectangle',
    slot: '9030101848',
    responsive: 'true',
  },
};
