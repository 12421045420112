import { clsx } from 'clsx';

import type { RectangleBannerProps } from './type';

import { bannerAttributes } from '@/components/features/banner/util';
import { AnchorLink, Image } from '@/components/ui';
import { CONTENT_WIDTH, NO_IMAGE_SRC } from '@/constants';
import { isExternal } from '@/utils/features/banner';

export const RectangleBanner = ({ banners }: RectangleBannerProps) => {
  return (
    <div className={clsx('tw-flex tw-flex-col tw-items-center tw-gap-y-5')}>
      {banners
        .flatMap((b) => (b.url ? { ...b, url: b.url } : []))
        .map((banner, index) => (
          <div
            className={clsx(
              'lg:tw-max-w-[300px] lg:tw-h-[197px]',
              'lg:tw-block'
            )}
            key={index}
          >
            <AnchorLink
              {...bannerAttributes({ id: banner.id, type: banner.type })}
              href={banner.url}
              target={isExternal(banner.url) ? '_blank' : '_self'}
            >
              <Image
                src={banner.thumbnail?.file?.url ?? NO_IMAGE_SRC}
                alt={`バナー${index}`}
                objectFit="contain"
                priority
                size={{
                  sp: `${CONTENT_WIDTH.sp}px`,
                  tablet: `${CONTENT_WIDTH.tablet}px`,
                  pc: '300px',
                }}
              />
            </AnchorLink>
          </div>
        ))}
    </div>
  );
};
