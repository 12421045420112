export const bannerAttributes = ({
  id,
  type,
}: {
  id: number | null | undefined;
  type: string | null | undefined;
}) => ({
  'banner-id': id ?? 'error',
  'banner-type': type ?? 'error',
});
