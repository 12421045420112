import {
  IconCompanyKoubo,
  IconCompanyService,
  IconCompanyWeb,
} from '@/components/icons/companies';
import { ROUTE } from '@/constants';

export const ORGANIZER_HELP_LINKS = [
  {
    label: '公募を掲載したい',
    href: ROUTE.publicationRequest(),
    icon: IconCompanyKoubo,
  },
  {
    label: 'サービスを知りたい',
    href: ROUTE.companyService(),
    icon: IconCompanyService,
  },
  {
    label: 'WEB受け付けをしたい',
    href: ROUTE.planner(),
    icon: IconCompanyWeb,
  },
];
