import type { IconBaseProps } from '@/components/icons/type';

export const IconCompanyKoubo = ({ boxSize = '2em' }: IconBaseProps) => {
  return (
    <svg
      width={boxSize}
      height={boxSize}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_246_1670)">
        <path
          d="M17.7501 2.75H7.57011C7.32847 2.75261 7.08972 2.8028 6.86748 2.89769C6.64524 2.99258 6.44386 3.13031 6.27486 3.30303C6.10585 3.47575 5.97253 3.68007 5.88249 3.90432C5.79245 4.12857 5.74747 4.36836 5.75011 4.61V26.89C5.74747 27.1316 5.79245 27.3714 5.88249 27.5957C5.97253 27.8199 6.10585 28.0242 6.27486 28.197C6.44386 28.3697 6.64524 28.5074 6.86748 28.6023C7.08972 28.6972 7.32847 28.7474 7.57011 28.75H23.9301C24.1717 28.7474 24.4105 28.6972 24.6327 28.6023C24.855 28.5074 25.0564 28.3697 25.2254 28.197C25.3944 28.0242 25.5277 27.8199 25.6177 27.5957C25.7078 27.3714 25.7528 27.1316 25.7501 26.89V10.75"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.53 10.75H25.75L17.75 2.75V8.97C17.75 9.44209 17.9375 9.89484 18.2714 10.2287C18.6052 10.5625 19.0579 10.75 19.53 10.75V10.75Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.75 15.75H20.75"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.75 20.75H20.75"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
