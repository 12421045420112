import {} from 'react';
import { clsx } from 'clsx';

import type { SidebarLayoutProps } from './type';

export const SidebarLayout = ({
  sidebar,
  isLeftSidebar = false,
  children,
}: SidebarLayoutProps) => {
  return (
    <div
      className={clsx(
        'tw-grid tw-grid-cols-1',
        'lg:tw-gap-x-16',
        isLeftSidebar
          ? 'lg:tw-grid-cols-[300px_auto]'
          : 'lg:tw-grid-cols-[auto_300px]'
      )}
    >
      {isLeftSidebar && (
        <div
          className={clsx(
            'tw-w-full tw-mb-10',
            'md:tw-max-w-tabletContent md:tw-mx-auto md:tw-mb-12 md:tw-pb-0',
            'lg:tw-mb-0'
          )}
        >
          {sidebar}
        </div>
      )}

      <div className="tw-w-full md:tw-max-w-tabletContent lg:tw-max-w-pcMainContent tw-mx-auto">
        {children}
      </div>

      {!isLeftSidebar && (
        <div
          className={clsx(
            'tw-w-full tw-mt-10',
            'md:tw-max-w-tabletContent md:tw-mx-auto md:tw-mt-12 md:tw-pb-0',
            'lg:tw-mt-0'
          )}
        >
          {sidebar}
        </div>
      )}
    </div>
  );
};
