import type { IconBaseProps } from '@/components/icons/type';

export const IconCompanyWeb = ({ boxSize = '2em' }: IconBaseProps) => {
  return (
    <svg
      width={boxSize}
      height={boxSize}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_246_1678)">
        <path
          d="M27.4199 3.75H4.07995C3.77134 3.75262 3.46627 3.81599 3.18215 3.93651C2.89804 4.05704 2.64044 4.23234 2.42408 4.45242C2.20772 4.67249 2.03682 4.93303 1.92115 5.21916C1.80549 5.50528 1.74731 5.81139 1.74995 6.12V20.37C1.74599 20.6794 1.80318 20.9866 1.91826 21.2739C2.03333 21.5612 2.20402 21.8229 2.42051 22.0441C2.637 22.2652 2.89504 22.4414 3.17982 22.5625C3.46459 22.6837 3.77049 22.7474 4.07995 22.75H27.4199C27.7294 22.7474 28.0353 22.6837 28.3201 22.5625C28.6049 22.4414 28.8629 22.2652 29.0794 22.0441C29.2959 21.8229 29.4666 21.5612 29.5816 21.2739C29.6967 20.9866 29.7539 20.6794 29.7499 20.37V6.12C29.7526 5.81139 29.6944 5.50528 29.5787 5.21916C29.4631 4.93303 29.2922 4.67249 29.0758 4.45242C28.8595 4.23234 28.6019 4.05704 28.3177 3.93651C28.0336 3.81599 27.7286 3.75262 27.4199 3.75Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.75 27.5498H22.75"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3501 22.75V27.55"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.1499 22.75V27.55"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.75 18.7402H29.75"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
